import logo from "./assets/logo-min.png"
import allmoImg from "./assets/allmo-img.jpg"
import allegroImg from "./assets/allegro-img.webp"
import sliderBiuro from "./assets/slides/slider_biuro.png"
import sliderChemia from "./assets/slides/slider_opakowania.png"
import sliderOpakowania from "./assets/slides/slider_chemia.png"
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Autoplay, Navigation} from "swiper/modules";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export function Main() {

    const images = [sliderBiuro, sliderOpakowania, sliderChemia];

    return (
        <>
            <header>
                <nav className="bg-white border-gray-200 dark:bg-[#0F2409]">
                    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                        <a href="https://opalkapolska.pl/" className="flex items-center space-x-3 rtl:space-x-reverse">
                            <img src={logo} className="h-8" alt="Opałka Polska Logo"/>
                            <span
                                className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Opałka Polska</span>
                        </a>
                        <button data-collapse-toggle="navbar-default" type="button"
                                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                aria-controls="navbar-default" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 17 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M1 1h15M1 7h15M1 13h15"/>
                            </svg>
                        </button>
                        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-[#0F2409] dark:border-gray-700">
                                <li>
                                    <a href="#"
                                       className="block py-2 px-3 text-gray-900 rounded hover:bg-[#0F2409] md:hover:bg-transparent md:border-0 md:hover:text-[#58CF39] md:p-0 dark:text-white md:dark:hover:text-[#58CF39] dark:hover:bg-[#0F2409] dark:hover:text-white md:dark:hover:bg-transparent">
                                        O nas</a>
                                </li>
                                <li>
                                    <a href="#company-info"
                                       className="block py-2 px-3 text-gray-900 rounded hover:bg-[#0F2409] md:hover:bg-transparent md:border-0 md:hover:text-[#58CF39] md:p-0 dark:text-white md:dark:hover:text-[#58CF39] dark:hover:bg-[#0F2409] dark:hover:text-white md:dark:hover:bg-transparent">
                                        Kontakt</a>
                                </li>
                                <li>
                                    <a href="#contact-us"
                                       className="block py-2 px-3 text-gray-900 rounded hover:bg-[#0F2409] md:hover:bg-transparent md:border-0 md:hover:text-[#58CF39] md:p-0 dark:text-white md:dark:hover:text-[#58CF39] dark:hover:bg-[#0F2409] dark:hover:text-white md:dark:hover:bg-transparent">
                                        Lokalizacja</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <main>
                <div id="container"
                     className="flex flex-col align-middle justify-center lg:w-full bg-gray-50 dark:bg-gray-800">
                    <section id="about-us" className="sm:w-3/4 lg:w-1/2 mx-auto text-center">
                        <h1 className="my-8 text-4xl font-extrabold tracking-tight leading-none text-gray-950 md:text-5xl lg:text-6xl dark:text-gray-50">
                            Wierzymy w <span className="text-[#58cf39]">handel</span></h1>
                        <p className="font-light h-full mt-4 dark:text-gray-50 dark:font-normal">Od 1999 roku
                            dostarczamy innowacyjne rozwiązania
                            zaopatrzeniowe dla biznesów wszelkiego rodzaju – od sklepów i biur, po bary, restauracje i
                            zakłady produkcyjne.</p>
                        <p className="font-light h-full mt-4 dark:text-gray-50 dark:font-normal">Specjalizujemy się w
                            oferowaniu szerokiej gamy produktów:
                            opakowania, materiały eksploatacyjne, urządzenia, środki czystości i więcej.</p>
                        <p className="font-light h-full mt-4 dark:text-gray-50 dark:font-normal">Nasze elastyczne
                            warunki dostaw i płatności, atrakcyjne
                            ceny oraz gwarancja szybkich i precyzyjnych dostaw tworzą idealne warunki dla Twojej
                            firmy.</p>
                        <p className="font-light h-full mt-4 dark:text-gray-50 dark:font-normal">Oferujemy również
                            towary detaliczne. Jeśli poszukujesz
                            niezawodnego partnera w biznesie, który rozumie Twoje potrzeby – jesteśmy tu dla Ciebie!</p>
                    </section>
                    <section id="cards"
                             className="w-full flex flex-col md:flex-row items-center lg:items-stretch lg:mx-auto flex-wrap my-3 justify-around lg:my-8">
                        <div
                            className="block max-w-sm p-6 rounded-lg shadow bg-gray-100 dark:bg-[#0F2409] m-1">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-50 text-center">Skuteczność</h5>
                            <p className="font-normal text-gray-500 dark:text-gray-200 text-center">Od 1999 roku
                                dostarczamy skuteczne zaopatrzenie dla różnych branż, gwarantując zadowolenie i
                                efektywność w każdym wymiarze biznesu.</p>
                        </div>
                        <div
                            className="block max-w-sm p-6 rounded-lg shadow bg-gray-100 dark:bg-[#0F2409] m-1">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-50 text-center">Profesjonalizm</h5>
                            <p className="font-normal text-gray-500 dark:text-gray-200 text-center">Łączymy
                                profesjonalizm z elastycznością, oferując konkurencyjne ceny i precyzyjne dostawy, aby
                                wspierać Twój biznes na każdym kroku.</p>
                        </div>
                        <div
                            className="block max-w-sm p-6 rounded-lg shadow bg-gray-100 dark:bg-[#0F2409] m-1">
                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-50 text-center">Doświadczenie</h5>
                            <p className="font-normal text-gray-500 dark:text-gray-200 text-center">Ponad 20 lat
                                doświadczenia, nasze sprawdzone rozwiązania zaopatrzeniowe są kluczem do sukcesu Twojego
                                przedsiębiorstwa.</p>
                        </div>
                    </section>
                    <section className="lg:h-[50rem] w-full">
                        <p className="font-bold text-xl lg:text-2xl text-gray-900 dark:text-white text-center mx-auto my-4">Przykładowe
                            artykuly z naszej oferty</p>

                        <Swiper
                            modules={[Navigation, A11y, Autoplay]}
                            slidesPerGroup={1}
                            slidesPerView={1}
                            loop={true}
                            autoplay={{
                                delay: 6000,
                                disableOnInteraction: false,
                            }}
                            navigation
                        >
                            {images.map((img, idx) => (
                                <SwiperSlide key={idx}>
                                    <img src={img} alt={`Slide ${idx}`}
                                         className="h-full w-auto object-scale-down mx-auto"/>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </section>
                    <section id="about-us" className="sm:w-5/6 lg:w-1/2 mx-auto text-center my-4">
                        <p className="font-bold text-xl lg:text-2xl mt-3 text-gray-900 dark:text-gray-200"> Nasze sklepy
                            internetowe: </p>
                        <a href="https://allmo.pl" target="_blank" rel="noopener noreferrer"
                           className="flex flex-col items-center border border-gray-200 dark:border-0 rounded-lg shadow md:flex-row hover:bg-gray-100 bg-gray-100 hover:bg-green-100 dark:bg-[#0F2409] dark:hover:bg-green-800 my-4">
                            <img
                                className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
                                src={allmoImg} alt=""/>
                            <div className="flex flex-col justify-between p-4 leading-normal w-full">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-800 dark:text-gray-50 mx-auto">Allmo</h5>
                                <p className="mb-3 font-normal text-gray-500 dark:text-gray-100">Nasz
                                    własny sklep internetowy.</p>
                            </div>
                        </a>
                        <a href="https://allegro.pl/uzytkownik/opalkadlafirm" target="_blank" rel="noopener noreferrer"
                           className="flex flex-col items-center border border-gray-200 dark:border-0 rounded-lg shadow md:flex-row hover:bg-gray-100 bg-gray-100 hover:bg-green-100 dark:bg-[#0F2409] dark:hover:bg-green-800 my-4">
                            <img
                                className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
                                src={allegroImg} alt=""/>
                            <div className="flex flex-col justify-between p-4 leading-normal mx-auto">
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-800 dark:text-gray-50 mx-auto">Allegro</h5>
                                <p className="mb-3 font-normal text-gray-500 dark:text-gray-100">Oficjalne konto na
                                    portalu Allegro.</p>
                            </div>
                        </a>

                    </section>
                    <section id="contact-us" className="sm:w-5/6 lg:w-full mx-auto text-center flex flex-col my-2">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2459495.6069495655!2d17.588276748780036!3d52.98707801588807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e22daf38095745%3A0x41622706b84b8d48!2sOpa%C5%82ka%20Polska%20sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1704740301078!5m2!1spl!2spl"
                            className="w-full border-0 my-2" height="450" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        <div id="company-info"
                             className="block sm:w-5/6 max-w-xl mx-auto my-6 p-6 bg-white rounded-xl shadow-md overflow-hidden dark:bg-[#0F2409] bg-gray-100">
                            <h5 className="mb-4 text-2xl font-bold text-gray-800 dark:text-gray-50 text-center">Dane
                                firmy</h5>
                            <div className="grid grid-cols-2 gap-4">
                                <p className="font-semibold text-gray-700 dark:text-gray-100">Adres:</p>
                                <p className="font-normal text-gray-700 dark:text-gray-200">Armii Krajowej 37, 11-600
                                    Węgorzewo</p>

                                <p className="font-semibold text-gray-700 dark:text-gray-100">Nazwa Firmy:</p>
                                <p className="font-normal text-gray-700 dark:text-gray-200">Opałka Polska sp. z o.o.</p>

                                <p className="font-semibold text-gray-700 dark:text-gray-100">NIP:</p>
                                <p className="font-normal text-gray-700 dark:text-gray-200">8452003550</p>

                                <p className="font-semibold text-gray-700 dark:text-gray-100">REGON:</p>
                                <p className="font-normal text-gray-700 dark:text-gray-200">527211743</p>

                                <p className="font-semibold text-gray-700 dark:text-gray-100">KRS:</p>
                                <p className="font-normal text-gray-700 dark:text-gray-200">0001075956</p>

                                <p className="font-semibold text-gray-700 dark:text-gray-100">Numer telefonu:</p>
                                <p className="font-normal text-gray-700 dark:text-gray-200">+48 729 840 336</p>

                                <p className="font-semibold text-gray-700 dark:text-gray-100">Adres email:</p>
                                <p className="font-normal text-gray-700 dark:text-gray-200">biuro@opalkapolska.pl</p>
                            </div>
                        </div>

                    </section>
                </div>
            </main>
            <footer className="w-full">
                <span
                    className="block sm:h-20 lg:h-14 w-full bg-gray-100 dark:bg-[#0F2409] text-sm text-gray-800 font-semibold text-center dark:text-gray-50 p-4">
                    © 2024 <a href="https://opalkapolska.pl/" className="hover:underline my-auto">Opałka Polska</a>. Wszystkie prawa zastrzeżone.</span>
            </footer>
        </>
    )
}